import React, { useState, useEffect } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Logo3D } from '../Logo3D.tsx';
import { FiCode, FiBox, FiCpu } from 'react-icons/fi';
import { DroneforgeButton } from '../DroneforgeButton.tsx';
import { FiTwitter, FiMessageCircle, FiMail } from 'react-icons/fi';
import { FaDiscord } from 'react-icons/fa';
import { Modal } from '../Modal.tsx';
import WaitlistForm from '../WaitlistForm.tsx';
import { FiArrowRight } from 'react-icons/fi';

export const FeatureCard: React.FC<{
  title: string;
  description: string;
  icon: React.ReactNode;
  delay: number;
}> = ({ title, description, icon, delay }) => {
  const [spinCount, setSpinCount] = React.useState(0);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleCardClick = () => {
    setSpinCount(prev => prev + 1);
  };

  return (
    <motion.div
      className={`relative perspective-1500 cursor-pointer w-full ${isMobile ? 'mobile-card rounded-2xl' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      onClick={handleCardClick}
    >
      <motion.div
        className="relative h-full"
        whileHover={isMobile ? {
          scale: 1.02,
          transition: { duration: 0.3, ease: "easeOut" }
        } : {
          rotateX: "-10deg",
          rotateY: "-15deg",
          transition: { duration: 0.4, ease: "easeOut" }
        }}
        whileTap={{ scale: 0.98 }}
        style={{
          transformStyle: "preserve-3d",
          transformOrigin: "center center"
        }}
      >
        <div className={`
          relative h-full glass-panel 
          ${isMobile ? 'p-3 rounded-lg' : 'p-4 sm:p-6 rounded-xl'} 
          border border-[#00F135]/10 
          hover:border-[#00F135]/30 
          transition-all duration-300 
          group backdrop-blur-sm
        `}>
          {/* Refined Shimmer Effect - Only on Desktop */}
          {!isMobile && (
            <div className="absolute inset-0 rounded-xl overflow-hidden">
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <motion.div 
                  className="absolute inset-0"
                  initial={{ x: '-100%' }}
                  animate={{ x: '100%' }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "linear",
                    repeatDelay: 1
                  }}
                  style={{
                    background: 'linear-gradient(90deg, transparent 0%, rgba(0,241,53,0.05) 45%, rgba(0,241,53,0.1) 50%, rgba(0,241,53,0.05) 55%, transparent 100%)',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </div>
            </div>
          )}

          {/* Enhanced 3D Shadow Effect - Only on Desktop */}
          {!isMobile && (
            <div 
              className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500"
              style={{
                background: 'linear-gradient(225deg, transparent 50%, rgba(0,241,53,0.1) 100%)',
                transform: 'translateZ(-40px)',
                pointerEvents: 'none'
              }}
            />
          )}

          <div className="relative z-10 space-y-2">
            <div className="flex items-center justify-between gap-2">
              <motion.h3 
                className={`text-white font-horizon ${isMobile ? 'text-base' : 'text-lg sm:text-xl'}`}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: delay + 0.2 }}
              >
                {title}
              </motion.h3>

              <motion.div
                className={`
                  relative rounded-lg bg-[#00F135]/10 
                  flex items-center justify-center
                  ${isMobile ? 'w-8 h-8' : 'w-10 h-10 sm:w-12 sm:h-12 group-hover:scale-110'}
                  transition-transform duration-300
                `}
                animate={{ rotate: spinCount * 360 }}
                transition={{ duration: 0.7 }}
              >
                <div className={`text-[#00F135] ${isMobile ? 'text-lg' : 'text-xl sm:text-2xl'}`}>
                  {icon}
                </div>
                {!isMobile && (
                  <div className="absolute inset-0 rounded-lg bg-[#00F135]/20 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                )}
              </motion.div>
            </div>

            <motion.p 
              className={`text-white/60 ${isMobile ? 'text-xs' : 'text-xs sm:text-sm'} text-left leading-snug`}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: delay + 0.3 }}
            >
              {description}
            </motion.p>
          </div>

          {/* Hover Glow Effect - Only on Desktop */}
          {!isMobile && (
            <div className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500">
              <div className="absolute inset-0 bg-gradient-to-bl from-transparent to-[#00F135]/5 rounded-xl" />
              <div className="absolute inset-0 bg-[#00F135]/5 blur-xl" />
            </div>
          )}
        </div>

        {/* 3D Lift Effect - Only on Desktop */}
        {!isMobile && (
          <div 
            className="absolute inset-0 rounded-xl bg-[#00F135]/5"
            style={{
              transform: 'translateZ(-40px)',
              filter: 'blur(15px)',
              opacity: 0,
              transition: 'opacity 0.3s ease'
            }}
          />
        )}
      </motion.div>
    </motion.div>
  );
};

export const MobileFeatureCard: React.FC<{
  title: string;
  description: string;
  icon: React.ReactNode;
  delay: number;
}> = ({ title, description, icon, delay }) => {
  const [spinCount, setSpinCount] = React.useState(0);

  const handleCardClick = () => {
    setSpinCount(prev => prev + 1);
  };

  return (
    <motion.div
      className="relative perspective-1500 cursor-pointer w-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      onClick={handleCardClick}
    >
      <motion.div
        className="relative h-full"
        whileHover={{
          rotateX: "-10deg",
          rotateY: "-15deg",
          transition: { duration: 0.4, ease: "easeOut" }
        }}
        whileTap={{ scale: 0.98 }}
        style={{
          transformStyle: "preserve-3d",
          transformOrigin: "center center"
        }}
      >
        <div className="relative h-full glass-panel p-3.5 rounded-xl border border-[#00F135]/10 hover:border-[#00F135]/30 transition-all duration-300 group backdrop-blur-sm">
          <div className="relative z-10">
            <div className="flex items-center gap-3">
              <motion.div
                className="relative w-9 h-9 rounded-lg bg-[#00F135]/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300"
                animate={{ rotate: spinCount * 360 }}
                transition={{ duration: 0.7 }}
              >
                <div className="text-[#00F135] text-lg">
                  {icon}
                </div>
                <div className="absolute inset-0 rounded-lg bg-[#00F135]/20 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </motion.div>

              <motion.h3 
                className="text-white font-horizon text-base"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: delay + 0.2 }}
              >
                {title}
              </motion.h3>
            </div>

            <motion.p 
              className="text-white/60 text-xs text-left leading-relaxed mt-2 pl-12"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: delay + 0.3 }}
            >
              {description}
            </motion.p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const MobileFeatureFlow: React.FC<{ delay: number }> = ({ delay }) => {
  return (
    <motion.div
      className="flex items-center justify-between w-full px-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay }}
    >
      <motion.div
        className="flex items-center"
        initial={{ x: -20 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay }}
      >
        <div className="text-[#00F135] text-sm font-horizon">BUILD</div>
        <FiArrowRight className="text-[#00F135]/50 mx-3" />
      </motion.div>

      <motion.div
        className="flex items-center"
        initial={{ x: -10 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay: delay + 0.1 }}
      >
        <div className="text-[#00F135] text-sm font-horizon">TEST</div>
        <FiArrowRight className="text-[#00F135]/50 mx-3" />
      </motion.div>

      <motion.div
        initial={{ x: -20 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay: delay + 0.2 }}
      >
        <div className="text-[#00F135] text-sm font-horizon">DEPLOY</div>
      </motion.div>
    </motion.div>
  );
};

const FeatureStep: React.FC<{
  text: string;
  delay: number;
  isLast?: boolean;
}> = ({ text, delay, isLast }) => (
  <motion.div
    className="flex items-center"
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay }}
  >
    <div className="relative">
      <div className="absolute inset-0 bg-[#00F135]/5 blur-lg rounded-lg" />
      <div className="relative px-3 py-1.5 rounded-lg bg-black/20 backdrop-blur-sm border border-[#00F135]/10">
        <span className="text-white/90 text-xs font-horizon">{text}</span>
      </div>
    </div>
    {!isLast && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: delay + 0.1 }}
        className="mx-2"
      >
        <FiArrowRight className="text-[#00F135]/40 text-xs" />
      </motion.div>
    )}
  </motion.div>
);

export const HeroSection: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [showContent, setShowContent] = React.useState(false);
  const [isWaitlistOpen, setIsWaitlistOpen] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  const [screenSize, setScreenSize] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getTitleSize = () => {
    if (screenSize < 640) return 'text-4xl'; // Mobile
    if (screenSize < 768) return 'text-5xl'; // Small tablet
    if (screenSize < 1024) return 'text-6xl'; // Large tablet
    if (screenSize < 1280) return 'text-6xl'; // Small desktop
    return 'text-7xl'; // Large desktop
  };

  React.useEffect(() => {
    // Initial loading state
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Show content after transition
    const contentTimer = setTimeout(() => {
      setShowContent(true);
    }, 3500);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  const handleContactClick = () => {
    window.location.href = 'mailto:chesterzelaya@berkeley.edu';
  };

  const mobileFeatures = [
    {
      icon: <FiCode />,
      title: "BUILD",
      description: "Create custom drone AI programs",
      delay: 0.4
    },
    {
      icon: <FiBox />,
      title: "TEST",
      description: "Validate in 3D environments",
      delay: 0.5
    },
    {
      icon: <FiCpu />,
      title: "DEPLOY",
      description: "Run on real drone hardware",
      delay: 0.6
    }
  ];

  if (isMobile) {
    return (
      <section className="relative w-full min-h-screen bg-black overflow-hidden">
        {/* Background Effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-radial from-black via-black to-[#001]" />
          <div className="absolute inset-0 hero-gradient opacity-50" />
          <div className="absolute top-[20%] left-[25%] w-[50%] h-[50%] bg-[#00F135]/[0.03] blur-[150px] rounded-full" />
        </div>

        {!showContent && (
          <motion.div 
            className="absolute w-[250px] h-[250px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            initial={{ opacity: 1 }}
            animate={{ opacity: isLoading ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <Canvas
              camera={{ position: [0, 0, 10], fov: 45 }}
              style={{ background: 'transparent' }}
            >
              <React.Suspense fallback={null}>
                <ambientLight intensity={0.3} />
                <pointLight position={[10, 10, 10]} intensity={0.7} color="#00F135" />
                <Logo3D />
                <OrbitControls
                  enableZoom={false}
                  enablePan={false}
                  autoRotate
                  autoRotateSpeed={2}
                />
              </React.Suspense>
            </Canvas>
          </motion.div>
        )}

        {showContent && (
          <div className="relative z-10 min-h-screen flex flex-col">
            {/* Logo */}
            <motion.div 
              className="absolute top-6 left-6 w-[60px] h-[60px]"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Canvas
                camera={{ position: [0, 0, 10], fov: 45 }}
                style={{ background: 'transparent' }}
              >
                <React.Suspense fallback={null}>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 10, 10]} intensity={0.7} color="#00F135" />
                  <Logo3D />
                  <OrbitControls
                    enableZoom={false}
                    enablePan={false}
                    autoRotate
                    autoRotateSpeed={2}
                  />
                </React.Suspense>
              </Canvas>
            </motion.div>

            {/* Main Content */}
            <div className="flex-1 flex items-center justify-center px-5">
              <motion.div
                className="w-full max-w-[340px] mx-auto space-y-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                {/* Title */}
                <div className="text-center space-y-4 flex flex-col items-center">
                  <motion.div 
                    className="flex justify-center w-full"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <h1 className={`
                      font-horizon tracking-tight text-white
                      ${getTitleSize()}
                      ${isMobile ? 'mobile-text-balance px-2' : 'px-4'}
                    `}>
                      THE DRONE <span className="bg-gradient-to-r from-[#00F135] to-[#00F135]/50 text-transparent bg-clip-text">AI</span> PLATFORM
                    </h1>
                  </motion.div>

                  <motion.div
                    className="space-y-3 w-full flex flex-col items-center"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <div className="flex items-center justify-center">
                      <FeatureStep text="BUILD" delay={0.4} />
                      <FeatureStep text="TEST" delay={0.6} />
                      <FeatureStep text="DEPLOY" delay={0.8} isLast />
                    </div>
                  </motion.div>
                </div>

                {/* Description with more spacing */}
                <div className="pt-2">
                  <motion.p
                    className="text-white/70 text-xs text-center font-mono leading-relaxed"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.9 }}
                  >
                    Create end-to-end Drone AI pipelines in photo-realistic simulations. 
                    Deploy model pipelines with one button into our hardware platform.
                    <br /><br />
                  
                  </motion.p>
                </div>

                {/* CTA Buttons */}
                <motion.div 
                  className="space-y-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1 }}
                >
                  <DroneforgeButton
                    variant="accent"
                    size="lg"
                    className="w-full text-sm"
                    onClick={() => setIsWaitlistOpen(true)}
                  >
                    Join the Early Access Beta
                  </DroneforgeButton>

                  <div className="flex justify-center gap-3">
                    <DroneforgeButton
                      href="https://discord.gg/xFh3xxfyUh"
                      variant="primary"
                      size="sm"
                      className="text-xs"
                    >
                      <FaDiscord />
                      <span>Discord</span>
                    </DroneforgeButton>

                    <DroneforgeButton
                      href="https://twitter.com/thedroneforge"
                      variant="primary"
                      size="sm"
                      className="text-xs"
                    >
                      <FiTwitter />
                      <span>X</span>
                    </DroneforgeButton>

                    <DroneforgeButton
                      variant="primary"
                      size="sm"
                      className="text-xs"
                      onClick={handleContactClick}
                    >
                      <FiMail />
                      <span>Contact</span>
                    </DroneforgeButton>
                  </div>
                </motion.div>

                {/* Desktop Version Prompt */}
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.1 }}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-[#00F135]/5 blur-lg rounded-full" />
                    <p className="relative text-white/60 text-xs font-mono">
                      Visit our desktop version for the full experience
                    </p>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        )}

        <Modal
          isOpen={isWaitlistOpen}
          onClose={() => setIsWaitlistOpen(false)}
        >
          <WaitlistForm onSuccess={() => setIsWaitlistOpen(false)} />
        </Modal>
      </section>
    );
  }

  // Desktop version remains exactly the same
  return (
    <section className="relative w-full min-h-screen flex items-center justify-center bg-black overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Base gradient for depth */}
        <div className="absolute inset-0 bg-gradient-radial from-black via-black to-[#001]" />
        
        {/* Subtle tech grid */}
        <div className="absolute inset-0 tech-grid opacity-10" />
        
        {/* Main gradient orb */}
        <div className="absolute inset-0 hero-gradient" />

        {/* Secondary subtle gradients */}
        <div className="absolute inset-0">
          <div className="absolute top-[15%] left-[25%] w-[50%] h-[50%] bg-[#00F135]/[0.03] blur-[150px] rounded-full" />
          <div className="absolute bottom-[15%] right-[25%] w-[45%] h-[45%] bg-[#00F135]/[0.025] blur-[130px] rounded-full" />
        </div>

        {/* Accent highlights */}
        <div 
          className="absolute inset-0"
          style={{
            background: 'linear-gradient(135deg, transparent 60%, rgba(0,241,53,0.04) 100%), linear-gradient(45deg, rgba(0,241,53,0.03) 0%, transparent 40%)'
          }}
        />

        {/* Refined vignette */}
        <div className="absolute inset-0 bg-gradient-radial from-transparent via-black/40 to-black/80" />
      </div>

      {/* Large Centered Logo */}
      {!showContent && (
        <motion.div 
          className={`absolute ${isMobile ? 'w-[200px] h-[200px]' : 'w-[300px] h-[300px] sm:w-[600px] sm:h-[600px]'}`}
          initial={{ opacity: 1 }}
          animate={{ opacity: isLoading ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Canvas
            camera={{ 
              position: [0, 0, 10], 
              fov: 45,
              near: 1,
              far: 100
            }}
            style={{
              background: 'transparent',
              width: '100%',
              height: '100%',
            }}
            dpr={window.devicePixelRatio}
            gl={{
              antialias: true,
              alpha: true,
              powerPreference: "high-performance",
            }}
          >
            <React.Suspense fallback={null}>
              <ambientLight intensity={0.3} />
              <pointLight position={[10, 10, 10]} intensity={0.7} color="#00F135" />
              <pointLight position={[-10, -10, -10]} intensity={0.3} color="#00F135" />
              <Logo3D />
              <OrbitControls
                enableZoom={false}
                enablePan={false}
                autoRotate
                autoRotateSpeed={2}
                maxPolarAngle={Math.PI / 2}
                minPolarAngle={Math.PI / 2}
                enableDamping={true}
                dampingFactor={0.05}
              />
            </React.Suspense>
          </Canvas>
        </motion.div>
      )}

      {/* Main Content with Mobile Optimizations */}
      {showContent && (
        <motion.div 
          className={`
            relative z-10 
            ${isMobile ? 'mobile-compact-layout mobile-section-spacing pt-20 pb-8' : 'container mx-auto mt-20'}
          `}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="flex flex-col items-center text-center">
            <motion.div 
              className={`w-full max-w-4xl mx-auto ${isMobile ? 'space-y-8' : 'space-y-8 sm:space-y-11'}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              {/* Title Section */}
              <motion.div 
                className={`${isMobile ? 'space-y-3' : 'space-y-3 sm:space-y-5'}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <h1 className={`
                  font-horizon tracking-tight text-white
                  ${getTitleSize()}
                  ${isMobile ? 'mobile-text-balance px-2' : 'px-4'}
                `}>
                  THE DRONE <span className="bg-gradient-to-r from-[#00F135] to-[#00F135]/50 text-transparent bg-clip-text">AI</span> PLATFORM
                </h1>
              </motion.div>

              {/* Main Content */}
              <motion.div 
                className={`${isMobile ? 'space-y-8' : 'space-y-8 sm:space-y-11'}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                <p className={`
                  text-white/90 font-mono leading-relaxed 
                  ${isMobile ? 'text-base mobile-text-balance' : 'text-lg sm:text-xl px-2'}
                `}>
                  Build and perfect your drone AI programs. Test in photorealistic simulations. Deploy to real drones.
                </p>

                {/* Feature Cards */}
                <div className={`
                  grid 
                  ${isMobile ? 'grid-cols-1 gap-4' : 'sm:grid-cols-3 gap-4 sm:gap-7 px-2'}
                `}>
                  {mobileFeatures.map((feature, index) => (
                    <FeatureCard
                      key={index}
                      title={feature.title}
                      description={feature.description}
                      icon={feature.icon}
                      delay={feature.delay}
                    />
                  ))}
                </div>
              </motion.div>

              {/* CTA Section */}
              <motion.div 
                className={`${isMobile ? 'space-y-6' : 'space-y-6 pt-4 sm:pt-7'}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <div className={`flex justify-center px-4`}>
                  <DroneforgeButton
                    variant="accent"
                    size="lg"
                    className="w-full sm:w-auto"
                    onClick={() => setIsWaitlistOpen(true)}
                  >
                    Join the Early Access Beta
                  </DroneforgeButton>
                </div>

                <div className="flex justify-center items-center gap-2 sm:gap-3 text-white/50 text-sm flex-wrap px-4">
                  <DroneforgeButton
                    href="https://discord.gg/xFh3xxfyUh"
                    variant="primary"
                    size="sm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaDiscord className="text-base" />
                    <span>Discord</span>
                  </DroneforgeButton>

                  <span>•</span>

                  <DroneforgeButton
                    href="https://twitter.com/thedroneforge"
                    variant="primary"
                    size="sm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiTwitter />
                    <span>X</span>
                  </DroneforgeButton>

                  <span>•</span>

                  <DroneforgeButton
                    variant="secondary"
                    size="sm"
                    onClick={() => window.location.href = 'mailto:chesterzelaya@berkeley.edu'}
                  >
                    <FiMail />
                    <span>Contact Me</span>
                  </DroneforgeButton>
                </div>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      )}

      <Modal
        isOpen={isWaitlistOpen}
        onClose={() => setIsWaitlistOpen(false)}
      >
        <WaitlistForm onSuccess={() => setIsWaitlistOpen(false)} />
      </Modal>
    </section>
  );
}; 