import React from 'react';
import { motion } from 'framer-motion';
import { FiBox, FiTool } from 'react-icons/fi';

export const HardwareSection: React.FC = () => {
  const [flippedCard, setFlippedCard] = React.useState<'prebuilt' | 'diy' | null>(null);

  const options = [
    {
      name: 'Analog',
      description: 'Basic analog FPV drone kit with all necessary components',
      price: '$299',
    },
    {
      name: 'Analog + VRX',
      description: 'Analog FPV drone kit with video receiver included',
      price: '$399',
    },
    {
      name: 'Digital',
      description: 'Digital FPV system for crystal clear video transmission',
      price: '$499',
    },
    {
      name: 'Digital + VRX',
      description: 'Complete digital FPV system with video receiver',
      price: '$599',
    }
  ];

  const materials = [
    {
      category: 'Flight Controller',
      items: [
        { name: 'F405 Flight Controller', price: '$49.99', link: '#' },
        { name: '4-in-1 ESC', price: '$39.99', link: '#' },
      ]
    },
    {
      category: 'Frame & Motors',
      items: [
        { name: '5-inch Drone Frame', price: '$29.99', link: '#' },
        { name: '2207 2400KV Motors (4x)', price: '$79.99', link: '#' },
      ]
    },
    {
      category: 'Video System',
      items: [
        { name: 'FPV Camera', price: '$29.99', link: '#' },
        { name: 'Video Transmitter', price: '$39.99', link: '#' },
      ]
    },
  ];

  const handleCardClick = (card: 'prebuilt' | 'diy') => {
    setFlippedCard(flippedCard === card ? null : card);
  };

  return (
    <section className="relative w-full min-h-[80vh] bg-black flex flex-col">
      {/* Title section - Adjusted padding */}
      <div className="text-center pt-8 sm:pt-12 lg:pt-16 pb-8 sm:pb-12">
        <motion.h2 
          className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-horizon text-white mb-4 sm:mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <span className="text-white">DRONE</span>
          <span className="bg-gradient-to-r from-[#00F135] to-[#00F135]/50 text-transparent bg-clip-text">
            FORGE
          </span>
          <span className="text-white"> STARTER KIT</span>
        </motion.h2>
        <motion.p
          className="text-gray-400 text-base sm:text-lg lg:text-xl max-w-3xl mx-auto px-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Deploy real AI pipelines with our pre-assembled kit or build it yourself.
        </motion.p>
      </div>

      {/* Content Container - Adjusted padding */}
      <div className="flex-1 flex justify-center items-center gap-4 sm:gap-6 lg:gap-8 px-4 sm:px-6 lg:px-10 py-0">
        {/* Prebuilt Kit Card */}
        <motion.div
          className="relative perspective-1500 cursor-pointer w-[90%] sm:w-[40%] lg:w-[32%] aspect-square"
          onClick={() => handleCardClick('prebuilt')}
          whileHover={flippedCard === null ? {
            rotateX: "-10deg",
            rotateY: "-15deg",
            translateY: -5,
            transition: {
              duration: 0.4,
              ease: "easeOut"
            }
          } : {}}
        >
          {/* Gradient Orb - Enhanced */}
          <div className="absolute inset-0 pointer-events-none">
            <div 
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[150%] h-[150%]"
              style={{
                background: 'radial-gradient(circle at center, rgba(0,241,53,0.25) 0%, rgba(0,241,53,0.2) 20%, rgba(0,241,53,0.15) 40%, transparent 70%)',
                filter: 'blur(60px)',
                opacity: 0.8
              }}
            />
          </div>

          <motion.div
            className="relative h-full"
            animate={{
              rotateY: flippedCard === 'prebuilt' ? 180 : 0,
              rotateX: flippedCard === 'prebuilt' ? -10 : 0,
            }}
            transition={{
              duration: 1.2,
              type: "spring",
              stiffness: 60,
              damping: 20,
              mass: 1.5
            }}
            style={{
              transformStyle: "preserve-3d",
              transformOrigin: "center center"
            }}
          >
            {/* Front of card */}
            <div 
              className="absolute inset-0 backface-hidden"
              style={{ backfaceVisibility: 'hidden' }}
            >
              <div className="relative h-full glass-panel p-4 sm:p-5 lg:p-7 rounded-xl border border-[#00F135]/10 hover:border-[#00F135]/30 transition-all duration-300 group backdrop-blur-sm">
                {/* Content */}
                <div className="relative z-10 flex flex-col items-center justify-center h-full space-y-3 sm:space-y-4 lg:space-y-6">
                  {/* Icon */}
                  <motion.div
                    className="text-[#00F135] text-3xl sm:text-4xl lg:text-5xl mb-2"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                  >
                    <FiBox />
                  </motion.div>

                  <motion.h3 
                    className="text-xl sm:text-2xl lg:text-[2.5rem] font-horizon tracking-[0.15em] text-white/90 text-center"
                    whileHover={{ color: '#00F135' }}
                  >
                    Pre-built Kit
                  </motion.h3>
                  <p className="text-sm sm:text-base lg:text-lg text-white/60 text-center max-w-lg">
                    Complete drone kit with all components pre-assembled and tested
                  </p>
                  <div className="flex items-center gap-2 sm:gap-3 lg:gap-4 text-[#00F135]/70">
                    <span className="text-sm sm:text-base lg:text-lg tracking-[0.25em] font-light">VIEW OPTIONS</span>
                    <motion.span 
                      className="text-lg sm:text-xl lg:text-2xl"
                      animate={{ x: [0, 4, 0] }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    >
                      →
                    </motion.span>
                  </div>
                </div>

                {/* Hover Glow Effect */}
                <div className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500">
                  <div className="absolute inset-0 bg-gradient-to-bl from-transparent to-[#00F135]/5 rounded-xl" />
                  <div className="absolute inset-0 bg-[#00F135]/5 blur-xl" />
                </div>
              </div>
            </div>

            {/* Back of card - Update text sizes */}
            <div 
              className="absolute inset-0 backface-hidden"
              style={{ 
                backfaceVisibility: 'hidden',
                transform: 'rotateY(180deg)'
              }}
            >
              <div className="relative h-full glass-panel p-4 sm:p-5 lg:p-7 rounded-xl border border-[#00F135]/10 bg-black/80 backdrop-blur-sm">
                <div className="relative z-10 h-full flex flex-col">
                  <h3 className="text-xl sm:text-2xl font-horizon text-[#00F135] mb-2 sm:mb-3 lg:mb-4">Choose Your Kit</h3>
                  <div className="flex-1 space-y-2 sm:space-y-3 overflow-auto">
                    {options.map((option, index) => (
                      <motion.div
                        key={option.name}
                        className="glass-panel p-3 sm:p-4 rounded-lg border border-[#00F135]/20 hover:border-[#00F135]/40 transition-all duration-300"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        <div className="flex justify-between items-center">
                          <div>
                            <h4 className="text-white text-xs sm:text-sm">{option.name}</h4>
                            <p className="text-gray-400 text-xs hidden sm:block">{option.description}</p>
                          </div>
                          <div className="text-right">
                            <div className="text-[#00F135] font-mono text-xs sm:text-sm mb-1">{option.price}</div>
                            <motion.button
                              className="bg-[#00F135]/10 hover:bg-[#00F135]/20 text-[#00F135] px-2 sm:px-3 py-1 rounded text-xs border border-[#00F135]/30"
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                            >
                              Pre-order
                            </motion.button>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                  <motion.button
                    className="mt-4 text-[#00F135]/70 text-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFlippedCard(null);
                    }}
                  >
                    ← Back to kit
                  </motion.button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>

        {/* DIY Kit Card */}
        <motion.div
          className="relative perspective-1500 cursor-pointer w-[90%] sm:w-[40%] lg:w-[32%] aspect-square"
          onClick={() => handleCardClick('diy')}
          whileHover={flippedCard === null ? {
            rotateX: "10deg",
            rotateY: "-15deg",
            translateY: -5,
            transition: {
              duration: 0.4,
              ease: "easeOut"
            }
          } : {}}
        >
          {/* Gradient Orb - Enhanced */}
          <div className="absolute inset-0 pointer-events-none">
            <div 
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[150%] h-[150%]"
              style={{
                background: 'radial-gradient(circle at center, rgba(0,123,255,0.25) 0%, rgba(0,123,255,0.2) 20%, rgba(0,123,255,0.15) 40%, transparent 70%)',
                filter: 'blur(60px)',
                opacity: 0.8
              }}
            />
          </div>

          <motion.div
            className="relative h-full"
            animate={{
              rotateY: flippedCard === 'diy' ? 180 : 0,
              rotateX: flippedCard === 'diy' ? 10 : 0,
            }}
            transition={{
              duration: 1.2,
              type: "spring",
              stiffness: 60,
              damping: 20,
              mass: 1.5
            }}
            style={{
              transformStyle: "preserve-3d",
              transformOrigin: "center center"
            }}
          >
            {/* Front of DIY card */}
            <div 
              className="absolute inset-0 backface-hidden"
              style={{ backfaceVisibility: 'hidden' }}
            >
              <div className="relative h-full glass-panel p-4 sm:p-5 lg:p-7 rounded-xl border border-[#007BFF]/10 hover:border-[#007BFF]/30 transition-all duration-300 group backdrop-blur-sm">
                {/* Content */}
                <div className="relative z-10 flex flex-col items-center justify-center h-full space-y-3 sm:space-y-4 lg:space-y-6">
                  {/* Icon */}
                  <motion.div
                    className="text-[#007BFF] text-3xl sm:text-4xl lg:text-5xl mb-2"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                  >
                    <FiTool />
                  </motion.div>

                  <motion.h3 
                    className="text-xl sm:text-2xl lg:text-[2.5rem] font-horizon tracking-[0.15em] text-white/90 text-center"
                    whileHover={{ color: '#007BFF' }}
                  >
                    DIY Kit
                  </motion.h3>
                  <p className="text-sm sm:text-base lg:text-lg text-white/60 text-center max-w-lg">
                    Build your own custom drone with our curated selection of parts
                  </p>
                  <div className="flex items-center gap-2 sm:gap-3 lg:gap-4 text-[#007BFF]/70">
                    <span className="text-sm sm:text-base lg:text-lg tracking-[0.25em] font-light">EXPLORE PARTS</span>
                    <motion.span 
                      className="text-lg sm:text-xl lg:text-2xl"
                      animate={{ x: [0, 4, 0] }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    >
                      →
                    </motion.span>
                  </div>
                </div>

                {/* Hover Glow Effect */}
                <div className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500">
                  <div className="absolute inset-0 bg-gradient-to-bl from-transparent to-[#007BFF]/5 rounded-xl" />
                  <div className="absolute inset-0 bg-[#007BFF]/5 blur-xl" />
                </div>
              </div>
            </div>

            {/* Back of DIY card */}
            <div 
              className="absolute inset-0 backface-hidden"
              style={{ 
                backfaceVisibility: 'hidden',
                transform: 'rotateY(180deg)'
              }}
            >
              <div className="relative h-full glass-panel p-4 sm:p-5 lg:p-7 rounded-xl border border-[#007BFF]/10 bg-black/80 backdrop-blur-sm">
                <div className="relative z-10 h-full flex flex-col">
                  <h3 className="text-xl sm:text-2xl font-horizon text-[#007BFF] mb-2 sm:mb-3 lg:mb-4">Build Your Kit</h3>
                  <div className="flex-1 space-y-2 sm:space-y-3 overflow-auto">
                    {materials.map((category, categoryIndex) => (
                      <motion.div
                        key={category.category}
                        className="glass-panel p-3 sm:p-4 rounded-lg border border-[#007BFF]/20"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: categoryIndex * 0.1 }}
                      >
                        <h4 className="text-xs sm:text-sm font-mono text-[#007BFF] mb-1 sm:mb-2">{category.category}</h4>
                        <div className="space-y-1 sm:space-y-2">
                          {category.items.map((item, itemIndex) => (
                            <div key={item.name} className="flex justify-between items-center">
                              <p className="text-white text-xs sm:text-sm">{item.name}</p>
                              <div className="flex items-center gap-2 sm:gap-3">
                                <span className="text-gray-400 text-xs sm:text-sm">{item.price}</span>
                                <motion.button
                                  className="bg-[#007BFF]/10 hover:bg-[#007BFF]/20 text-[#007BFF] px-2 sm:px-3 py-1 rounded text-xs border border-[#007BFF]/30"
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                >
                                  Add
                                </motion.button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    ))}
                  </div>
                  <motion.button
                    className="mt-2 sm:mt-4 text-[#007BFF]/70 text-xs sm:text-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFlippedCard(null);
                    }}
                  >
                    ← Back to kit
                  </motion.button>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}; 