import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaCode, FaRobot, FaGlobeAmericas } from 'react-icons/fa';

const FEATURE_VIDEOS = {
  'test-models': '/easy_to_program.mp4',
  'navigation': '/dev4.mp4',
  'environments': '/custom_maps.mp4'
} as const;

const VideoPreloader: React.FC = () => {
  return (
    <div className="hidden">
      {Object.values(FEATURE_VIDEOS).map((src) => (
        <link key={src} rel="preload" as="video" href={src} type="video/mp4" />
      ))}
    </div>
  );
};

const FeatureCard: React.FC<{
  title: string;
  description: string;
  icon: React.ReactNode;
  isActive: boolean;
  onMouseEnter: () => void;
}> = ({ title, description, icon, isActive, onMouseEnter }) => {
  const [spinCount, setSpinCount] = React.useState(0);

  const handleCardClick = () => {
    setSpinCount(prev => prev + 1);
  };

  return (
    <motion.div
      className="relative perspective-1500 cursor-pointer"
      onClick={handleCardClick}
      onMouseEnter={onMouseEnter}
    >
      <motion.div
        className="relative h-full"
        whileHover={{
          rotateX: "-5deg",
          rotateY: "7deg",
          transition: {
            duration: 0.4,
            ease: "easeOut"
          }
        }}
        style={{
          transformStyle: "preserve-3d",
          transformOrigin: "center center"
        }}
      >
        <div className={`relative h-full glass-panel p-6 rounded-xl border transition-all duration-300 group backdrop-blur-sm ${
          isActive 
            ? 'border-[#00F135]/30 bg-[#00F135]/10' 
            : 'border-[#00F135]/10 hover:border-[#00F135]/30'
        }`}>
          {/* Shimmer Effect */}
          <div className="absolute inset-0 rounded-xl overflow-hidden">
            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
              <motion.div 
                className="absolute inset-0"
                initial={{ x: '-100%' }}
                animate={{ x: '100%' }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "linear",
                  repeatDelay: 1
                }}
                style={{
                  background: 'linear-gradient(90deg, transparent 0%, rgba(0,241,53,0.05) 45%, rgba(0,241,53,0.1) 50%, rgba(0,241,53,0.05) 55%, transparent 100%)',
                  width: '100%',
                  height: '100%'
                }}
              />
            </div>
          </div>

          {/* Content */}
          <div className="relative z-10 flex items-start gap-4">
            <motion.div
              className="relative w-16 h-14 rounded-lg bg-[#00F135]/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300"
              animate={{ rotate: spinCount * 360 }}
              transition={{ duration: 0.7 }}
            >
              <div className="text-[#00F135] text-2xl">
                {icon}
              </div>
              <div className="absolute inset-0 rounded-lg bg-[#00F135]/20 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </motion.div>

            <div className="space-y-2">
              <h3 className="text-white font-horizon text-lg">
                {title}
              </h3>
              <p className="text-white/60 text-sm">
                {description}
              </p>
            </div>
          </div>

          {/* Hover Glow Effect */}
          <div className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500">
            <div className="absolute inset-0 bg-gradient-to-bl from-transparent to-[#00F135]/5 rounded-xl" />
            <div className="absolute inset-0 bg-[#00F135]/5 blur-xl" />
          </div>
        </div>

        {/* 3D Lift Effect */}
        <div 
          className="absolute inset-0 rounded-xl bg-[#00F135]/5"
          style={{
            transform: 'translateZ(-40px)',
            filter: 'blur(15px)',
            opacity: 0,
            transition: 'opacity 0.3s ease'
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export const SimulationShowcaseSection: React.FC = () => {
  const [activeFeature, setActiveFeature] = React.useState('test-models');
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2
  });

  const videoRefs = React.useRef<{ [key: string]: HTMLVideoElement | null }>({});

  React.useEffect(() => {
    Object.entries(FEATURE_VIDEOS).forEach(([key, src]) => {
      const video = document.createElement('video');
      video.src = src;
      video.preload = 'auto';
      video.load();
      videoRefs.current[key] = video;
    });
  }, []);

  return (
    <motion.section 
      ref={ref}
      className="relative w-full bg-black overflow-hidden pb-0"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <VideoPreloader />
      <div className="absolute inset-0">
        {/* Circuit Board Grid - Enhanced version */}
        <div className="absolute inset-0 tech-grid opacity-20">
          <div className="absolute inset-0" style={{
            backgroundImage: `
              linear-gradient(to right, rgba(0,241,53,0.1) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(0,241,53,0.1) 1px, transparent 1px),
              radial-gradient(circle at 50% 50%, rgba(0,241,53,0.2) 0%, transparent 2%)
            `,
            backgroundSize: '30px 30px, 30px 30px, 60px 60px',
            maskImage: 'radial-gradient(circle at center, black 60%, transparent 100%)'
          }} />
        </div>

        {/* Drone Wireframe Silhouette */}
        <motion.div 
          className="absolute top-1/4 right-[15%] w-[400px] h-[400px] opacity-20"
          animate={{
            y: [0, -20, 0],
            rotate: [0, 5, 0],
          }}
          transition={{
            duration: 6,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <svg
            viewBox="0 0 200 200"
            className="w-full h-full"
            style={{
              stroke: '#00F135',
              strokeWidth: '1.5',
              fill: 'none',
              filter: 'drop-shadow(0 0 8px rgba(0,241,53,0.4))',
            }}
          >
            {/* Enhanced drone wireframe */}
            <g className="opacity-90">
              {/* Main arms */}
              <path d="M100 100 L70 70 L40 40 M100 100 L130 70 L160 40" 
                className="stroke-[#00F135]" strokeWidth="2" />
              <path d="M100 100 L70 130 L40 160 M100 100 L130 130 L160 160" 
                className="stroke-[#00F135]" strokeWidth="2" />
              
              {/* Center body */}
              <circle cx="100" cy="100" r="20" 
                className="stroke-[#00F135]" strokeWidth="2" />
              
              {/* Motors/propellers with subtle animation */}
              <motion.circle cx="40" cy="40" r="12"
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              <motion.circle cx="160" cy="40" r="12"
                animate={{ scale: [1.1, 1, 1.1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              <motion.circle cx="40" cy="160" r="12"
                animate={{ scale: [1, 1.1, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              <motion.circle cx="160" cy="160" r="12"
                animate={{ scale: [1.1, 1, 1.1] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              
              {/* Additional detail lines */}
              <path d="M90 100 L110 100 M100 90 L100 110" 
                className="stroke-[#00F135]" strokeWidth="1" />
            </g>
          </svg>
        </motion.div>

        {/* Keep existing vignette */}
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-40" />
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 lg:py-16 relative z-10">
        <div className="text-center mb-6 sm:mb-8 lg:mb-12 max-w-4xl mx-auto">
          <motion.h3 
            className="text-4xl lg:text-5xl text-white mb-6 font-horizon"
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Real-Time AI Development
          </motion.h3>

          <motion.h2 
            className="text-2xl font-mono text-[rgb(0,241,53)]"
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            Simulation Playground
          </motion.h2>
        </div>

        <div className="flex flex-col lg:flex-row items-start gap-4 lg:gap-12 max-w-7xl mx-auto">
          {/* Left Side - Content */}
          <motion.div 
            className="w-full lg:w-1/2 space-y-3 lg:space-y-6"
            initial={{ opacity: 0 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.8 }}
          >
            {/* Feature Selection */}
            <div className="space-y-3 sm:space-y-4 lg:space-y-6 pt-0 lg:pt-4">
              <FeatureCard
                title="Simple Drone Programming"
                description="Write and test drone code in a simple, intuitive, and fast-to-learn language"
                icon={<FaCode />}
                isActive={activeFeature === 'test-models'}
                onMouseEnter={() => setActiveFeature('test-models')}
              />

              <FeatureCard
                title="Built-In Autonomy"
                description="DroneForge includes a suite of built-in autonomy algorithms for navigation, obstacle avoidance, and more"
                icon={<FaRobot />}
                isActive={activeFeature === 'navigation'}
                onMouseEnter={() => setActiveFeature('navigation')}
              />

              <FeatureCard
                title="Custom Environments"
                description="Upload and test in custom 3D environments with configurable conditions"
                icon={<FaGlobeAmericas />}
                isActive={activeFeature === 'environments'}
                onMouseEnter={() => setActiveFeature('environments')}
              />
            </div>
          </motion.div>

          {/* Right Side - Video Preview */}
          <motion.div 
            className="w-full lg:w-1/2 lg:pt-[40px]"
            initial={{ opacity: 0, scale: 0.95, y: 30 }}
            animate={inView ? { 
              opacity: 1, 
              scale: 1, 
              y: 0,
            } : { opacity: 0, scale: 0.95, y: 30 }}
            transition={{ 
              duration: 1,
              delay: 0.4,
              type: "spring",
              stiffness: 100
            }}
          >
            <div className="relative aspect-video rounded-xl overflow-hidden glass-panel transform-gpu transition-transform duration-700 hover:scale-[1.01] mb-8 sm:mb-12 lg:mb-0">
              <div className="lg:sticky lg:top-[80px] max-h-[calc(100vh-160px)] overflow-hidden">
                <AnimatePresence mode="wait">
                  <motion.video 
                    key={activeFeature}
                    className="w-full h-full object-cover"
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                    preload="auto"
                    ref={(el) => {
                      if (el) videoRefs.current[activeFeature] = el;
                    }}
                    initial={{ opacity: 0, scale: 1.1 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 0.5 }}
                  >
                    <source src={FEATURE_VIDEOS[activeFeature]} type="video/mp4" />
                  </motion.video>
                </AnimatePresence>

                {/* Video Overlay */}
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                />
                
                {/* Feature Label */}
                <motion.div 
                  className="absolute bottom-0 left-0 right-0 p-6"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                >
                  <motion.div 
                    className="flex items-center gap-3 text-[rgb(0,241,53)] font-mono text-sm"
                    animate={{
                      scale: [1, 1.02, 1],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    <motion.div 
                      className="w-2 h-2 rounded-full bg-[rgb(0,241,53)]"
                      animate={{
                        scale: [1, 1.5, 1],
                        opacity: [1, 0.5, 1],
                      }}
                      transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                    <span>Active Feature Preview</span>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
}; 