import React, { Suspense, useRef, useEffect } from 'react'
import { useFrame, useLoader, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { STLLoader } from 'three-stdlib'

function Model() {
  const meshRef = useRef<THREE.Mesh>(null)
  const geometry = useLoader(STLLoader, '/logo.stl')
  const { gl, camera } = useThree()
  
  // Optimize WebGL renderer settings
  useEffect(() => {
    if (gl) {
      gl.setPixelRatio(Math.min(window.devicePixelRatio, 2))
      gl.toneMapping = THREE.NoToneMapping
      gl.outputColorSpace = THREE.SRGBColorSpace
      
      // Additional performance optimizations
      gl.shadowMap.enabled = false
      
      if (camera) {
        camera.near = 1
        camera.far = 100
        camera.updateProjectionMatrix()
      }
    }

    return () => {
      if (meshRef.current?.geometry) {
        meshRef.current.geometry.dispose()
      }
      if (meshRef.current?.material) {
        (meshRef.current.material as THREE.Material).dispose()
      }
    }
  }, [gl, camera])

  // Create and memoize material with original colors
  const material = React.useMemo(() => {
    const mat = new THREE.MeshPhysicalMaterial({
      color: new THREE.Color("rgb(0,241,53)"),
      emissive: new THREE.Color("rgb(0,241,53)"),
      emissiveIntensity: 0.5,
      roughness: 0.3,
      metalness: 0.8,
      envMapIntensity: 1,
      toneMapped: false,
    })
    
    return mat
  }, [])

  // Smooth rotation with lerp
  const targetRotation = useRef(0)
  const currentRotation = useRef(0)
  
  useFrame((state, delta) => {
    if (meshRef.current) {
      // Update target rotation
      targetRotation.current += delta * 0.5
      
      // Smooth interpolation
      currentRotation.current = THREE.MathUtils.lerp(
        currentRotation.current,
        targetRotation.current,
        0.1
      )
      
      meshRef.current.rotation.y = currentRotation.current
    }
  })

  return (
    <mesh 
      ref={meshRef} 
      geometry={geometry} 
      material={material} 
      scale={2} 
      position={[0, 0, 0]}
      frustumCulled={true}
      castShadow={false}
      receiveShadow={false}
      matrixAutoUpdate={true}
    >
      {/* Subtle glow with original color */}
      <pointLight
        position={[0, 0, 2]}
        intensity={0.3}
        color="rgb(0,241,53)"
        distance={5}
        decay={2}
      />
    </mesh>
  )
}

// Optimized canvas wrapper with performance monitoring
export const Logo3D = React.memo(() => {
  return (
    <Suspense fallback={null}>
      <Model />
    </Suspense>
  )
}) 