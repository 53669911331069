import React from 'react';
import { motion } from 'framer-motion';

interface DroneforgeButtonProps {
  href?: string;
  onClick?: () => void;
  variant?: 'primary' | 'secondary' | 'accent';
  size?: 'sm' | 'md' | 'lg';
  children: React.ReactNode;
  className?: string;
  target?: string;
  rel?: string;
}

export const DroneforgeButton: React.FC<DroneforgeButtonProps> = ({
  href,
  onClick,
  variant = 'primary',
  size = 'md',
  children,
  className = '',
  target,
  rel,
}) => {
  const baseStyles = "relative inline-flex items-center justify-center font-mono tracking-wide transition-all duration-300 whitespace-nowrap";
  
  const sizeStyles = {
    sm: "px-3 py-1.5 text-xs sm:px-4 sm:py-1.5 sm:text-sm",
    md: "px-4 py-2 text-sm sm:px-5 sm:py-2",
    lg: "px-5 py-2 text-sm sm:px-6 sm:py-2.5 sm:text-base",
  };

  const variantStyles = {
    primary: `
      bg-[#00F135]/5
      text-[#00F135]
      border
      border-[#00F135]/20
      hover:bg-[#00F135]/10
      active:bg-[#00F135]/15
      hover:border-[#00F135]/40
      hover:shadow-[0_0_15px_rgba(0,241,53,0.15)]
    `,
    secondary: `
      bg-white/5
      text-white/90
      border
      border-white/10
      hover:bg-white/10
      active:bg-white/15
      hover:border-white/20
      hover:shadow-[0_0_15px_rgba(255,255,255,0.1)]
    `,
    accent: `
      bg-gradient-to-r from-[#00F135]/90 to-[#00F135]/80
      text-black
      border
      border-[#00F135]/80
      hover:from-[#00F135] hover:to-[#00F135]/90
      active:from-[#00F135]/95 active:to-[#00F135]/85
      hover:shadow-[0_0_20px_rgba(0,241,53,0.2)]
    `,
  };

  const ButtonWrapper = motion.div;
  
  const Component = href ? 'a' : 'button';

  const buttonProps = {
    className: `
      ${baseStyles}
      ${sizeStyles[size]}
      ${variantStyles[variant]}
      ${className}
      rounded-full
      group
      overflow-hidden
      backdrop-blur-sm
      touch-manipulation
    `,
    href,
    onClick,
    target,
    rel,
  };

  return (
    <ButtonWrapper
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.97 }}
      className="touch-manipulation"
    >
      <Component {...buttonProps}>
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 group-active:opacity-100 transition-opacity duration-300">
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent animate-shimmer" />
        </div>
        
        <span className="relative z-10 flex items-center gap-1.5 sm:gap-2">{children}</span>
      </Component>
    </ButtonWrapper>
  );
};