import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { submitWaitlist } from '../api/api';

interface WaitlistFormProps {
  onSuccess?: () => void;
}

const WaitlistForm: React.FC<WaitlistFormProps> = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    interest: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await submitWaitlist(formData);
      alert('Thank you for joining our waitlist!');
      setFormData({ firstName: '', lastName: '', email: '', interest: '' });
      onSuccess?.();
    } catch (error) {
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <motion.div
      className="bg-zinc-900 p-8 rounded-lg shadow-lg max-w-md w-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-xl font-bold mb-6 text-green-500 text-center font-mono">join the waitlist for batch 2</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <motion.div
          className="relative"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.1 }}
        >
          <div className="flex space-x-4">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="first name"
              className="w-1/2 p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors text-white placeholder-white/30"
              required
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="last name"
              className="w-1/2 p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors text-white placeholder-white/30"
              required
            />
          </div>
        </motion.div>
        <motion.div
          className="relative"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="email"
            className="w-full p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors text-white placeholder-white/30"
            required
          />
        </motion.div>
        <motion.div
          className="relative"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <select
            name="interest"
            value={formData.interest}
            onChange={handleInputChange}
            className="w-full p-2 bg-transparent border-b border-green-500 focus:outline-none focus:border-green-300 transition-colors text-white placeholder-white/30"
            required
          >
            <option value="" disabled className="bg-zinc-900 text-white">Select your interest</option>
            <option value="educator" className="bg-zinc-900 text-white">Educator</option>
            <option value="developer" className="bg-zinc-900 text-white">Developer</option>
            <option value="commercial" className="bg-zinc-900 text-white">Commercial</option>
            <option value="other" className="bg-zinc-900 text-white">Other</option>
          </select>
        </motion.div>
        <motion.button
          type="submit"
          className="w-full bg-green-500 text-black font-bold py-2 px-4 rounded-full hover:bg-green-400 transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Join Waitlist
        </motion.button>
      </form>
    </motion.div>
  );
};

export default WaitlistForm; 