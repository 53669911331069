"use client"
import React from 'react';
import { HeroSection } from './sections/HeroSection.tsx';
import { SimulationShowcaseSection } from './sections/SimulationShowcaseSection.tsx';
import { HardwareSection } from './sections/HardwareSection.tsx';
import { Navbar } from './Navbar.tsx';
import './styles/globals.css';

const Landing_Bryce: React.FC = () => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 640;
  const [showContent, setShowContent] = React.useState(false);

  React.useEffect(() => {
    // Show content after initial loading animation (3.5 seconds)
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  if (isMobile) {
    return (
      <>
        <HeroSection />
      </>
    );
  }

  return (
    <div className="relative w-full h-screen overflow-y-auto overflow-x-hidden">
      <Navbar show={showContent} />
      <div className="relative w-full h-screen">
        <HeroSection />
      </div>
      <div className="relative w-full">
        <SimulationShowcaseSection />
      </div>
      <div className="relative w-full">
        <HardwareSection />
      </div>
    </div>
  );
};

export default Landing_Bryce;