import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { motion } from 'framer-motion';
import { FiUser, FiArrowLeft, FiLogOut, FiMail, FiCalendar } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const UserProfile: React.FC = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const [showContent, setShowContent] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="text-[#00F135] text-xl font-mono">Loading...</div>
      </div>
    );
  }

  if (!isAuthenticated || !user) {
    return <div>Please log in to view your profile.</div>;
  }

  const joinDate = user.updated_at ? new Date(user.updated_at).toLocaleDateString() : 'N/A';

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-black via-black to-[#001]" />
        <div className="absolute inset-0 tech-grid opacity-10" />
        <div className="absolute inset-0 hero-gradient opacity-50" />
        <div className="absolute top-[20%] left-[25%] w-[50%] h-[50%] bg-[#00F135]/[0.03] blur-[150px] rounded-full" />
      </div>

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          {/* Back Button */}
          <motion.button
            onClick={() => navigate('/')}
            className="flex items-center gap-2 text-[#00F135] hover:text-[#00F135]/80 transition-colors mb-8 group"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <FiArrowLeft className="group-hover:-translate-x-1 transition-transform" />
            <span>Back to Home</span>
          </motion.button>

          {/* Profile Card */}
          <motion.div
            className="bg-black/40 backdrop-blur-xl rounded-2xl border border-[#00F135]/10 overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: showContent ? 1 : 0, y: showContent ? 0 : 20 }}
            transition={{ duration: 0.5 }}
          >
            {/* Profile Header */}
            <div className="relative h-32 bg-gradient-to-r from-[#00F135]/10 to-transparent">
              <div 
                className="absolute inset-0" 
                style={{ 
                  backgroundImage: `linear-gradient(to right, rgba(0,241,53,0.05) 1px, transparent 1px),
                                  linear-gradient(to bottom, rgba(0,241,53,0.05) 1px, transparent 1px)`,
                  backgroundSize: '20px 20px'
                }} 
              />
            </div>

            {/* Profile Content */}
            <div className="px-8 pb-8 -mt-16">
              {/* Avatar */}
              <div className="relative mb-6">
                <div className="absolute -inset-1 bg-[#00F135]/20 rounded-full blur-lg" />
                {user.picture ? (
                  <img
                    src={user.picture}
                    alt={user.name}
                    className="relative w-32 h-32 rounded-full border-4 border-black bg-black"
                  />
                ) : (
                  <div className="relative w-32 h-32 rounded-full border-4 border-black bg-[#00F135]/10 flex items-center justify-center">
                    <FiUser className="w-16 h-16 text-[#00F135]" />
                  </div>
                )}
              </div>

              {/* User Info */}
              <div className="space-y-6">
                <div>
                  <h1 className="text-3xl font-bold text-white mb-2 font-horizon">{user.name}</h1>
                  <div className="flex items-center gap-2 text-white/60">
                    <FiMail className="text-[#00F135]" />
                    <span>{user.email}</span>
                  </div>
                </div>

                <div className="flex items-center gap-2 text-white/60">
                  <FiCalendar className="text-[#00F135]" />
                  <span>Joined {joinDate}</span>
                </div>

                {/* Stats */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 pt-4">
                  <div className="bg-[#00F135]/5 rounded-lg p-4 border border-[#00F135]/10 hover:bg-[#00F135]/10 transition-colors">
                    <div className="text-2xl font-bold text-white">0</div>
                    <div className="text-sm text-white/60">Simulations</div>
                  </div>
                  <div className="bg-[#00F135]/5 rounded-lg p-4 border border-[#00F135]/10 hover:bg-[#00F135]/10 transition-colors">
                    <div className="text-2xl font-bold text-white">0</div>
                    <div className="text-sm text-white/60">Models</div>
                  </div>
                  <div className="bg-[#00F135]/5 rounded-lg p-4 border border-[#00F135]/10 hover:bg-[#00F135]/10 transition-colors">
                    <div className="text-2xl font-bold text-white">Alpha</div>
                    <div className="text-sm text-white/60">Access Level</div>
                  </div>
                  <div className="bg-[#00F135]/5 rounded-lg p-4 border border-[#00F135]/10 hover:bg-[#00F135]/10 transition-colors">
                    <div className="text-2xl font-bold text-white">Active</div>
                    <div className="text-sm text-white/60">Status</div>
                  </div>
                </div>

                {/* Logout Button */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                  className="pt-6"
                >
                  <button
                    onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                    className="group relative px-6 py-3 w-full md:w-auto rounded-lg bg-red-500/10 hover:bg-red-500/20 text-red-500 transition-all duration-300 flex items-center justify-center gap-2"
                  >
                    <FiLogOut className="group-hover:rotate-180 transition-transform duration-300" />
                    <span>Log Out</span>
                  </button>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile; 