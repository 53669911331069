import React, { useState, useEffect, Suspense } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiChevronRight, FiUser, FiLogOut, FiMenu, FiX } from 'react-icons/fi';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Logo3D } from './Logo3D.tsx';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

interface NavButtonProps {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  isHighlighted?: boolean;
}

const NavButton: React.FC<NavButtonProps> = ({ href, onClick, children, className = '', isHighlighted = false }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    } else if (href) {
      e.preventDefault();
      if (href.startsWith('http')) {
        window.location.href = href;
      } else {
        navigate(href);
      }
    }
  };

  return (
    <motion.a 
      href={href || '#'}
      onClick={handleClick}
      className={`
        relative px-4 py-2 rounded-md font-medium
        transition-colors duration-300
        flex items-center gap-2
        ${isHighlighted ? 'text-black' : 'text-white/90'}
        ${className}
        cursor-pointer
      `}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {/* Background */}
      <motion.div
        className={`
          absolute inset-0 rounded-md z-0
          ${isHighlighted ? 'bg-[#00F135]' : 'bg-white/5'}
        `}
        initial={false}
        animate={{
          opacity: isHovered ? 1 : isHighlighted ? 1 : 0,
        }}
        transition={{ duration: 0.2 }}
      />

      {/* Glow effect */}
      <motion.div
        className={`
          absolute inset-0 rounded-md z-0
          ${isHighlighted ? 'bg-[#00F135]/50' : 'bg-white/20'}
        `}
        initial={false}
        animate={{
          opacity: isHovered ? 0.15 : 0,
        }}
        transition={{ duration: 0.2 }}
        style={{ filter: 'blur(8px)' }}
      />

      {/* Content */}
      <span className="relative z-10 font-mono tracking-wide text-sm">
        {children}
      </span>
      
      {/* Arrow indicator */}
      <motion.span
        className="relative z-10"
        initial={{ opacity: 0, x: -5 }}
        animate={{ opacity: isHovered ? 1 : 0, x: isHovered ? 0 : -5 }}
        transition={{ duration: 0.2 }}
      >
        <FiChevronRight className={isHighlighted ? 'text-black' : 'text-white'} />
      </motion.span>
    </motion.a>
  );
};

const ProfileDropdown: React.FC<{ user: any; onLogout: () => void }> = ({ user, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 rounded-md text-white/90 hover:bg-white/5 transition-colors duration-300"
      >
        <FiUser className="text-[#00F135]" />
        <span className="text-sm font-mono truncate max-w-[100px]">{user?.name}</span>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-48 py-2 bg-black/90 backdrop-blur-lg border border-[#00F135]/10 rounded-md shadow-lg shadow-[#00F135]/5"
          >
            <button
              onClick={() => {
                navigate('/profile');
                setIsOpen(false);
              }}
              className="w-full px-4 py-2 text-left text-white/90 hover:bg-[#00F135]/10 transition-colors duration-200 text-sm font-mono flex items-center gap-2"
            >
              <FiUser className="text-[#00F135]" />
              Profile
            </button>
            <button
              onClick={() => {
                onLogout();
                setIsOpen(false);
              }}
              className="w-full px-4 py-2 text-left text-white/90 hover:bg-[#00F135]/10 transition-colors duration-200 text-sm font-mono flex items-center gap-2"
            >
              <FiLogOut className="text-[#00F135]" />
              Logout
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const MobileMenu: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  isAuthenticated: boolean;
  user: any;
  onLogin: () => void;
  onLogout: () => void;
}> = ({ isOpen, onClose, isAuthenticated, user, onLogin, onLogout }) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    if (path.startsWith('http')) {
      window.location.href = path;
    } else {
      navigate(path);
    }
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, x: '100%' }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: '100%' }}
          transition={{ type: 'tween', duration: 0.3 }}
          className="fixed inset-0 z-50 bg-black/95 backdrop-blur-lg"
        >
          <div className="flex flex-col h-full">
            <div className="flex justify-end p-6">
              <button
                onClick={onClose}
                className="text-white/80 hover:text-white"
              >
                <FiX size={24} />
              </button>
            </div>
            
            <div className="flex flex-col items-center gap-6 p-6">
              <button 
                onClick={() => handleNavigation('https://thedroneforge.discourse.group/')}
                className="px-4 py-2 text-white/90 hover:bg-white/5 transition-colors duration-300 font-mono"
              >
                FORUM
              </button>
              <button 
                onClick={() => handleNavigation('/mission')}
                className="px-4 py-2 text-white/90 hover:bg-white/5 transition-colors duration-300 font-mono"
              >
                MISSION
              </button>
              <button 
                onClick={() => handleNavigation('/changelog')}
                className="px-4 py-2 text-white/90 hover:bg-white/5 transition-colors duration-300 font-mono"
              >
                CHANGELOG
              </button>
              <button 
                onClick={() => handleNavigation('/simulation')}
                className="px-4 py-2 text-[#00F135] bg-[#00F135]/10 hover:bg-[#00F135]/20 transition-colors duration-300 font-mono shadow-lg shadow-[#00F135]/20 hover:shadow-[#00F135]/30 rounded-md"
              >
                GO TO SIMULATION
              </button>
              
              {isAuthenticated ? (
                <>
                  <button 
                    onClick={() => handleNavigation('/profile')}
                    className="flex items-center gap-2 px-4 py-2 text-white/90 hover:bg-white/5 transition-colors duration-300 font-mono"
                  >
                    <FiUser className="text-[#00F135]" />
                    <span className="text-sm">{user?.name}</span>
                  </button>
                  <button 
                    onClick={() => { onLogout(); onClose(); }}
                    className="px-4 py-2 text-white/90 hover:bg-white/5 transition-colors duration-300 font-mono"
                  >
                    LOGOUT
                  </button>
                </>
              ) : (
                <button 
                  onClick={() => { onLogin(); onClose(); }}
                  className="px-4 py-2 text-white/90 hover:bg-white/5 transition-colors duration-300 font-mono"
                >
                  LOGIN
                </button>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const Navbar: React.FC<{ show: boolean }> = ({ show }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogin = () => {
    loginWithRedirect({
      appState: { returnTo: '/simulation' }
    });
  };

  const handleLogout = () => {
    logout({ 
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  if (!show) return null;

  return (
    <>
      <motion.nav 
        className="fixed top-0 w-full z-40 px-6 py-4"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div className={`
          glass-panel mx-auto max-w-7xl flex items-center justify-between
          transition-all duration-500 ease-in-out
          ${scrolled ? 'py-3 backdrop-blur-lg bg-black/50' : 'py-4 backdrop-blur-md bg-black/30'}
        `}>
          {/* Logo section remains the same ... */}
          <motion.a 
            href="/"
            className="flex items-center gap-3 group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {/* 3D Logo */}
            <div className="relative w-[40px] h-[40px]">
              <Canvas
                camera={{ 
                  position: [0, 0, 10], 
                  fov: 45,
                  near: 1,
                  far: 100
                }}
                style={{
                  background: 'transparent',
                  width: '100%',
                  height: '100%',
                }}
                dpr={window.devicePixelRatio}
              >
                <Suspense fallback={null}>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 10, 10]} intensity={0.7} color="#00F135" />
                  <pointLight position={[-10, -10, -10]} intensity={0.3} color="#00F135" />
                  <Logo3D />
                  <OrbitControls
                    enableZoom={false}
                    enablePan={false}
                    autoRotate
                    autoRotateSpeed={2}
                    maxPolarAngle={Math.PI / 2}
                    minPolarAngle={Math.PI / 2}
                    enableDamping={true}
                    dampingFactor={0.05}
                  />
                </Suspense>
              </Canvas>
            </div>

            {/* Text Logo */}
            <div className="relative">
              <div className="absolute inset-0 bg-[#00F135]/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <h1 className="relative font-horizon text-xl">
                <span className="text-white">DRONE</span>
                <span className="bg-gradient-to-r from-[#00F135] to-[#00F135]/50 text-transparent bg-clip-text">
                  FORGE
                </span>
              </h1>
            </div>
          </motion.a>

          {/* Navigation buttons - Only show on desktop */}
          {!isMobile ? (
            <div className="flex items-center gap-6">
              <NavButton href="https://thedroneforge.discourse.group/">FORUM</NavButton>
              <NavButton href="/mission">MISSION</NavButton>
              <NavButton href="/changelog">CHANGELOG</NavButton>
              <NavButton 
                href="/simulation"
                isHighlighted={true}
                className="shadow-lg shadow-[#00F135]/20 hover:shadow-[#00F135]/30"
              >
                GO TO SIMULATION
              </NavButton>
              
              {isAuthenticated ? (
                <ProfileDropdown user={user} onLogout={handleLogout} />
              ) : (
                <NavButton onClick={handleLogin}>
                  LOGIN
                </NavButton>
              )}
            </div>
          ) : (
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="text-white/80 hover:text-white p-2"
            >
              <FiMenu size={24} />
            </button>
          )}
        </motion.div>

        {/* Scroll indicator line */}
        <motion.div 
          className="absolute bottom-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-[#00F135]/30 to-transparent"
          initial={{ scaleX: 0, opacity: 0 }}
          animate={{ 
            scaleX: scrolled ? 1 : 0, 
            opacity: scrolled ? 1 : 0 
          }}
          transition={{ duration: 0.5 }}
        />
      </motion.nav>

      {/* Mobile Menu */}
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        isAuthenticated={isAuthenticated}
        user={user}
        onLogin={handleLogin}
        onLogout={handleLogout}
      />
    </>
  );
};

export default Navbar;