import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import posthog from 'posthog-js';

// Initialize PostHog
if (process.env.NODE_ENV === 'production') {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing();
    },
    capture_pageview: true, // Automatically capture pageviews
    capture_pageleave: true, // Capture when users leave your pages
    autocapture: true // Automatically capture clicks, form submissions etc
  });
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
