import React from 'react';
import { Navbar } from './Navbar.tsx';

export const Mission: React.FC = () => {
  return (
    <div className="relative min-h-screen bg-black">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-black via-black to-[#001]" />
        <div className="absolute inset-0 hero-gradient opacity-50" />
        <div className="absolute top-[15%] left-[25%] w-[50%] h-[50%] bg-[#00F135]/[0.03] blur-[150px] rounded-full" />
      </div>

      {/* Navbar */}
      <Navbar show={true} />

      {/* Content */}
      <div className="relative z-10 flex items-center justify-center min-h-screen px-4">
        <div className="max-w-3xl mx-auto text-center space-y-8 pt-20">
          <h1 className="font-horizon text-4xl sm:text-5xl text-white mb-12">
            OUR MISSION
          </h1>
          
          <p className="font-jetbrains text-lg sm:text-xl text-white/80 leading-relaxed">
            DroneForge is revolutionizing drone development by bridging the gap between 
            simulation and reality. We're building a platform where developers can create, 
            test, and deploy drone AI with unprecedented ease.
          </p>

          <p className="font-jetbrains text-lg sm:text-xl text-white/80 leading-relaxed">
            Our vision is to accelerate drone innovation by providing developers with 
            powerful tools for prototyping autonomous systems in photorealistic 
            environments, then seamlessly deploying them to real hardware.
          </p>

          <p className="font-jetbrains text-lg sm:text-xl text-[#00F135] leading-relaxed">
            Join us in shaping the future of autonomous flight.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Mission; 